import React, { useContext, useEffect, useState } from "react"
import loadable from "@loadable/component"
import CustomerContext from "../../context/customerContext"
import LoadingWrapper from "./components-shared/LoadingWrapper"
import { useCookies } from "react-cookie"
import { navigate } from "@reach/router"

const LoadingFallback = () => <>
<div className='customer-portal-top-section'>
        <div className='page-wrapper-fluid-grid '>
          <div className="customer-portal-logout">
            <LoadingWrapper condition={true} highlightColor="#38779A" width={55}/>
          </div>
          <div className="customer-portal-title">
            <LoadingWrapper condition={true} highlightColor="#38779A" width={414} height={67.2}/>
            <LoadingWrapper condition={true} highlightColor="#38779A" width={285}/>
          </div>

          <div className='customer-portal-nav-container'>
            <ul className='customer-portal-nav'>
              <LoadingWrapper condition={true} highlightColor="#38779A" width={98}/>
              <LoadingWrapper condition={true} highlightColor="#38779A" width={98}/>
              <LoadingWrapper condition={true} highlightColor="#38779A" width={98}/>
              <LoadingWrapper condition={true} highlightColor="#38779A" width={98}/>
            </ul>
          </div>
        </div>
      </div>
      <div className="order-history customer-portal-container ">
      <div className='page-wrapper-fluid-grid'>
        <h2 className="new-customer-portal-section-title" style={{"margin-bottom": "48px"}}>
          <LoadingWrapper condition={true}/>
        </h2>
      </div>

      <hr className='h-divider'/>

      <div className='insurance-download-section page-wrapper-fluid-grid'>
        <div className='left-text'>
          <LoadingWrapper condition={true} width={550}/>
          <LoadingWrapper condition={true} width={550}/>
        </div>
        <LoadingWrapper condition={true} height={56} width={300} baseColor={"#333"} highlightColor={"#fff"}/>
      </div>

      <hr className='h-divider-two'/>

      <div className='page-wrapper-fluid-grid'>
        <LoadingWrapper condition={true} height={287} count={3}/>
      </div>
    </div>
</>

const PortalComponent = loadable(props => import(`./${props.type}`), {fallback: LoadingFallback})

const CustomerPortal = ({ location }) => {
  const { stripeCustomer, setStripeCustomer } = useContext(CustomerContext)
  const [cookies] = useCookies(["user-jwt"])
  const user_token = cookies["user-jwt"]

  useEffect(() => {
    !user_token && navigate("/login")
  }, [user_token])

  useEffect(() => {
    const customerData = JSON.parse(localStorage.getItem("customer_data"))
    setStripeCustomer(customerData)
  }, [])

  if(!stripeCustomer?.type) {
    return <LoadingFallback />
  }

  return <PortalComponent location={location} type={stripeCustomer?.type} />
}

export default CustomerPortal
