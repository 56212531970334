import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PortalComponent from "../components/CustomerPortal"
import '../styles/pages/customer-portal.scss'

const CustomerPortal = ({ location }) => {
  return (
    <Layout>
      <SEO title="My Account" />
      <div className="customer-portal-wrapper">
        <PortalComponent location={location} />
      </div>
    </Layout>
  )
}

export default CustomerPortal;
